import React from "react";

export default class EmailForm extends React.Component {
  render() {
    return (
      <form onSubmit={this.props.handleEnterEmailSubmit} className="auth-reset-form">
        <fieldset>
          <label htmlFor="email">
            Email:
            <input
              placeholder="Enter your email"
              name="email"
              type="email"
              id="email"
              value={this.props.emailToReset}
              onChange={this.props.handleEmailChange}
              autoComplete="email"
            />
          </label>
        </fieldset>
        <button type="submit">Request password reset</button>
      </form>
    );
  }
}
