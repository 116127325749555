import React from "react";

export default class CodeForm extends React.Component {
  render() {
    return (
      <form onSubmit={this.props.handleEnterCodeSubmit} className="auth-reset-form">
        <p>
          If it exists in our database, the email address you provided has been sent a code. Copy
          that code into the field below and enter your chosen new password.
        </p>
        <fieldset>
          <label htmlFor="code">
            Code:
            <input
              placeholder="Enter your code"
              name="code"
              id="code"
              type="text"
              value={this.props.resetCode}
              onChange={this.props.handleCodeChange}
            />
          </label>
        </fieldset>
        <fieldset>
          <label htmlFor="newPassword">
            New password:
            <input
              placeholder="Enter new password"
              name="newPassword"
              id="newPassword"
              type="password"
              autoComplete="new-password"
              value={this.props.desiredNewPassword}
              onChange={this.props.handleNewPasswordChange}
            />
          </label>
        </fieldset>
        <button type="submit">Request password reset</button>
      </form>
    );
  }
}
