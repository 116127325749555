import React from "react";
import { Auth } from "aws-amplify";
import authTheme from "../authTheme";
import Amplify from "aws-amplify";

import EmailForm from "./emailForm";
import CodeForm from "./codeForm";

export default class ResetPassword extends React.Component {
  state = { emailToReset: "", stage: "enterEmail", resetCode: "", desiredNewPassword: "" };

  // Email Form methods
  handleEmailChange = (event) => {
    this.setState({ emailToReset: event.target.value });
  };

  handleEnterEmailSubmit = (e) => {
    this.resetPassword(this.state.emailToReset);
    e.preventDefault();
  };

  resetPassword = (value) => {
    this.setState({ stage: "loading" });
    Auth.forgotPassword(value)
      .then((data) => {
        this.setState({ stage: "enterCode" });
        console.log(data);
      })
      .catch((err) => {
        this.setState({ stage: "error" });
        console.log(err);
      });
  };

  // Code Form methods
  handleCodeChange = (e) => {
    this.setState({ resetCode: e.target.value });
  };

  handleNewPasswordChange = (e) => {
    this.setState({ desiredNewPassword: e.target.value });
  };

  handleEnterCodeSubmit = (e) => {
    const { emailToReset, resetCode, desiredNewPassword } = this.state;
    this.newPassword(emailToReset, resetCode, desiredNewPassword);
    e.preventDefault();
  };

  newPassword = (username, code, new_password) => {
    this.setState({ stage: "loading" });
    Auth.forgotPasswordSubmit(username, code, new_password)
      .then((data) => {
        this.setState({ stage: "complete" });
        console.log(data);
      })
      .catch((err) => {
        this.setState({ stage: "error" });
        console.log(err);
      });
  };

  renderForm() {
    switch (this.state.stage) {
      case "enterEmail":
        return (
          <EmailForm
            emailToReset={this.state.emailToReset}
            handleEmailChange={this.handleEmailChange}
            handleEnterEmailSubmit={this.handleEnterEmailSubmit}
          />
        );

      case "enterCode":
        return (
          <CodeForm
            handleCodeChange={this.handleCodeChange}
            handleEnterCodeSubmit={this.handleEnterCodeSubmit}
            handleNewPasswordChange={this.handleNewPasswordChange}
            resetCode={this.state.resetCode}
            emailToReset={this.state.emailToReset}
            desiredNewPassword={this.state.desiredNewPassword}
          />
        );
      case "complete":
        return (
          <div>
            <p>Your password has been reset.</p>
            <p className="password-home-link">
              <a href="/">Click here to go home.</a>
            </p>
          </div>
        );
      case "loading":
        return (
          <div>
            <p>Loading, please wait...</p>
          </div>
        );
      default:
        return <p>Sorry, an error has occurred. Please reload the page and try again.</p>;
    }
  }

  componentDidMount() {
    Amplify.configure(this.props.awsConfig);
  }

  render() {
    return (
      <main>
        <div className="auth-container" style={authTheme.container}>
          <h1>Password reset/change</h1>
          {this.renderForm()}
        </div>
      </main>
    );
  }
}
