import React from "react";
import PasswordReset from "../../../../../../shared_ui/layouts/passwordReset/index";
import "../../../../../../shared_ui/components/app.scss";

let awsConfig = {};
try {
  awsConfig = require("../../aws-exports").default;
} catch (error) {
  // Do nothing. This mess is only to turn a conditional import from an error into a warning
}

class ResetPage extends React.Component {
  componentDidMount() {}
  render() {
    return <PasswordReset awsConfig={awsConfig} />;
  }
}
export default ResetPage;
